
import React from 'react';
import { resolve } from 'path'
// import img from './static/images/404.png';
const r = path => resolve(__dirname, path)


class NotFound extends React.Component {
  constructor(props) {
		  super(props)
		  this.state = {
			     animated: ''
		       }
      this.enter = this.enter.bind(this)
	  }

    enter () {
        this.setState({animated: 'hinge'})
    };
    render() {
        return (
            <div className="center" style={{height: '100%', background: '#ececec', overflow: 'hidden'}}>
                <img src='https://qcloudtest-1256343699.cos.ap-guangzhou.myqcloud.com/404/404.png' alt="404" className={`animated swing ${this.state.animated}`} onMouseEnter={this.enter} />
            </div>
        )
    }
}

export default NotFound;
