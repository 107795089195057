import React from 'react'
import { Route, Redirect, Switch ,BrowserRouter} from 'react-router-dom';
// import 'antd/dist/antd.css';
import { enquireScreen } from 'enquire-js';
// import Loadable from 'react-loadable';

// import Home from './containers/Home'
import NotFound from './containers/404'
// import Loading from './components/loading'
// import Register from './containers/Register'
// import StepReg from './containers/StepReg'
// import RegStp1 from './containers/StepReg/Step1'
// import RegStp2 from './containers/StepReg/Step2'
// import RegStp3 from './containers/StepReg/Step3'
// import Login from './components/login'
// import Dashboard from './containers/Dashboard'
// import Admin from './admin'
// import AuthRoute from './components/authroute/authroute'
// import Map from './containers/Map'
// import Source from './containers/Source'
// import Sims from './containers/Sims'
// import Uploads from './containers/Uploads'
// import InOrOut from './containers/InOrOut'

// const MyLoadingComponent = ({ isLoading, error }) => {
//   if (isLoading) {
//     return <Loading/>;
//   }
//   else if (error) {
//     console.log(error)
//     return <NotFound/>;
//   }
//   else {
//     return null;
//   }
// }

// const Dashboard = Loadable({
//   loader: () => import('./containers/Dashboard'),
//   loading: MyLoadingComponent
// })

// const Map = Loadable({
//   loader: () => import('./containers/Map'),
//   loading: MyLoadingComponent
// })

// const Source = Loadable({
//   loader: () => import('./containers/Source'),
//   loading: MyLoadingComponent
// })

// const Sims = Loadable({
//   loader: () => import('./containers/Sims'),
//   loading: MyLoadingComponent
// })

// const Minasims = Loadable({
//   loader: () => import('./containers/Minasims'),
//   loading: MyLoadingComponent
// })
// const Minausers = Loadable({
//   loader: () => import('./containers/Minausers'),
//   loading: MyLoadingComponent
// })
// const MinaPrice = Loadable({
//   loader: () => import('./containers/MinaPrice'),
//   loading: MyLoadingComponent
// })

// const MinaPayLog = Loadable({
//   loader: () => import('./containers/MinaPayLog'),
//   loading: MyLoadingComponent
// })

// const Uploads = Loadable({
//   loader: () => import('./containers/Uploads'),
//   loading: MyLoadingComponent
// })

// const InOrOut = Loadable({
//   loader: () => import('./containers/InOrOut'),
//   loading: MyLoadingComponent
// })

// const RegStp1 = Loadable({
//   loader: () => import('./containers/StepReg/Step1'),
//   loading: MyLoadingComponent
// })

// const RegStp2 = Loadable({
//   loader: () => import('./containers/StepReg/Step2'),
//   loading: MyLoadingComponent
// })
// const StepReg = Loadable({
//   loader: () => import('./containers/StepReg'),
//   loading: MyLoadingComponent
// })

// const Login = Loadable({
//   loader: () => import('./components/login'),
//   loading: MyLoadingComponent
// })

// const Admin = Loadable({
//   loader: () => import('./admin'),
//   loading: MyLoadingComponent
// })

// const AuthRoute = Loadable({
//   loader: () => import('./components/authroute/authroute'),
//   loading: MyLoadingComponent
// })

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class RouterMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    }
  }
  componentDidMount() {
  // 适配手机屏幕;
  enquireScreen((b) => {
    this.setState({ isMobile: !!b });
  });
  // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；

}
  updatehandler(){
    console.log('bianhua');
  }
    render() {
        return(
          <BrowserRouter>
            <div className = 'root'>
            <Route path='*' component={NotFound}/>
            {/* <AuthRoute></AuthRoute>

              <Switch>
                <Route exact path="/login" component={Login} />
                <Route path="/register" render={()=>
                  <StepReg>
                    <Switch>
                      <Route path="/register/step1" component={RegStp1} />
                      <Route path="/register/step2" component={RegStp2} />
                      <Redirect to="/register/step1" />
                    </Switch>
                  </StepReg>
                }/>
                <Route  render={()=>
                            <Admin>
                                <Switch>
                                    <Route path='/admin/home' component={Dashboard} />
                                    <Route path='/admin/device/minasims' component={Minasims} />
                                    <Route path='/admin/user/minausers' component={Minausers} />
                                    <Route path='/admin/map' component={Map} />
                                    <Route path='/admin/device/sources' component={Source}/>
                                    <Route path='/admin/device/sims' component={Sims}/>
                                    <Route path='/admin/device/uploads' component={Uploads}/>
                                    <Route path='/admin/device/inorout' component={InOrOut}/>
                                    <Route path='/admin/billing/minaprice' component={MinaPrice}/>
                                    <Route path='/admin/billing/minapaylog' component={MinaPayLog}/>
                                   
                                </Switch>
                            </Admin>
                        } />
                <Route path='*' component={NotFound}/>
              </Switch> */}
            </div>
          </BrowserRouter>
        )
    }
}

export default RouterMap
