import React from 'react';
import { Select } from 'antd'
import moment from 'moment';
import { parse, stringify } from 'qs';


const Option = Select.Option;
export default {
  getOptionList(data){
    if(!data){
        return [];
    }
    let options = [] //[<Option value="0" key="all_key">全部</Option>];
    data.map((item)=>{
        options.push(<Option value={item.id} key={item.id}>{item.name}</Option>)
    })
    return options;
},
formateDate(time){
  if(!time)return '';
  let date = new Date(time);
  return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
},

// formateDate(date, rule) {
//   let fmt = rule || 'yyyy-MM-dd hh:mm:ss'
//   var datetem=new Date(date)
//   if (/(y+)/.test(fmt)) {
//       fmt = fmt.replace(RegExp.$1, datetem.getFullYear())
//   }
//   const o = {
//       // 'y+': date.getFullYear(),
//       'M+': datetem.getMonth() + 1,
//       'd+': datetem.getDate(),
//       'h+': datetem.getHours(),
//       'm+': datetem.getMinutes(),
//       's+': datetem.getSeconds()
//   }
//   for (let k in o) {
//       if (new RegExp(`(${k})`).test(fmt)) {
//           const val = o[k] + '';
//           fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? val : ('00' + val).substr(val.length));
//       }
//   }
//   return fmt;
// },
// 格式化金额,单位:分(eg:430分=4.30元)
formatFee(fee, suffix = '') {
  if (!fee) {
      return 0;
  }
  return Number(fee).toFixed(2) + suffix;
},
// 格式化公里（eg:3000 = 3公里）
formatMileage(mileage, text) {
  if (!mileage) {
      return 0;
  }
  if (mileage >= 1000) {
      text = text || " km";
      return Math.floor(mileage / 100) / 10 + text;
  } else {
      text = text || " m";
      return mileage + text;
  }
},
// 隐藏手机号中间4位
formatPhone(phone) {
  phone += '';
  return phone.replace(/(\d{3})\d*(\d{4})/g, '$1***$2')
},
// 隐藏身份证号中11位
formatIdentity(number) {
  number += '';
  return number.replace(/(\d{3})\d*(\d{4})/g, '$1***********$2')
},
}

export function getTimeDistance(type) {
    const now = new Date();
    const oneDay = 1000 * 60 * 60 * 24;
  
    if (type === 'today') {
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      return [moment(now), moment(now.getTime() + (oneDay - 1000))];
    }
  
    if (type === 'week') {
      let day = now.getDay();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
  
      if (day === 0) {
        day = 6;
      } else {
        day -= 1;
      }
  
      const beginTime = now.getTime() - day * oneDay;
  
      return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
    }
  
    if (type === 'month') {
      const year = now.getFullYear();
      const month = now.getMonth();
      const nextDate = moment(now).add(1, 'months');
      const nextYear = nextDate.year();
      const nextMonth = nextDate.month();
  
      return [
        moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
        moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
      ];
    }
  
    if (type === 'year') {
      const year = now.getFullYear();
  
      return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
    }
  }
  