
import axios from 'axios'
import request from '../util/request'
import {getRedirectPath} from '../util'
import Utils from '../util/utils'
import { Button,} from 'antd'


const AUTH_SUCCESS = 'AUTH_SUCCESS'
const ICCID_SUCCESS = 'ICCID_SUCCESS'
const INITREGTO = 'INITREGTO'
const LOGOUT = 'LOGOUT'
const ERROR_MSG = 'ERROR_MSG'
const LOAD_DATA = 'LOAD_DATA'
const REDIRECTTO = 'REDIRECTTO'
const ICCIDDATA = 'ICCIDDATA'
const initState={
	redirectTo:'',
	msg:'',
	username:'',
	type:'',
	phonenumber:'',
	role:'',
	iccidNum:'',
	bangown:'',//微信小程序设备，筛选条件，是否已绑定
	state:'',//微信小程序设备，筛选条件，卡状态
	search:'',//微信小程序设备,搜索条件
	minasimsortField:'',//微信小程序排序字段
	minasimsortOrder:'',//微信小程序排序方式
	minapricesortField:'',//微信小程序排序字段
	minapricesortOrder:'',//微信小程序排序方式
	minapaylogsortField:'',//微信小程序排序字段
	minapaylogsortOrder:'',//微信小程序排序方式
	minausersortField:'',//微信小程序排序字段
	minausersortOrder:'',//微信小程序排序方式
	minasimfield:[
		{
		  title: '操作',
		  isSelect:true,
		  //1是选中，2是未选择
		//   dataIndex: 'operate',
		  key: 'operationmini',
		  align:'center',
		//   sorter: true,
		//   render: name => `${name.first} ${name.last}`,
		  width: 80,
		  fixed:'left',
		  render: () => <Button type="link">编辑</Button>,
		},
		{
			title: 'ICCID',
			isSelect:true,
			key: 'iccidNum',
			ellipsis: true,
			// fixed:'left',
			dataIndex: 'iccidNum',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 250,
		  },
		  {
			title: 'MSISDN',
			isSelect:true,
			key: 'msisdn',
			ellipsis: true,
	
			dataIndex: 'msisdn',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 200,
		  },
		  {
			title: '手机号',
			isSelect:true,
			key: 'mobile',
			ellipsis: true,
	
			dataIndex: 'mobile',
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			// width: 150,
		  },
		  {
			title: '状态',
			isSelect:true,
			key: 'state',
			ellipsis: true,
	
			dataIndex: 'state',
			render(state){
				switch (state){
					case "-2": return ("测试");
					case "-1": return ("沉默期");
					case "0": return ("未激活");
					case "1": return ("已激活");
					case "2": return ("停机");
					case "3": return ("报警");
					case "4": return ("失效");
					case "5": return ("销户");
					default:return "未知状态"
				}
			  
			},
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 100,
		  },
		  {
			title: '绑定',
			key: 'bangown',
			isSelect:true,
			ellipsis: true,
	
			dataIndex: 'bangown',
			render(bangown){
				switch (bangown){
					case "1": return ("已绑定");
					case "2": return ("未绑定");
					default:return "未知状态"
				}
			  
			},
			// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
			width: 100,
		  },
		  {
			title: '创建时间',
			isSelect:true,
			key: 'createAt',
			ellipsis: true,
	
			dataIndex: "meta.createAt",
			sorter: true,
			render:Utils.formateDate,
			
		  },
		  {
			title: '更新时间',
			isSelect:true,
			key: 'updateAt',
			ellipsis: true,
			sorter: true,
			render:Utils.formateDate,
			dataIndex: "meta.updateAt",
			
		  }],//微信小程序动态显示字段
		  minapricefield:[
			{
			  title: '操作',
			//   dataIndex: 'operate',
			  key: 'operationmini',
			  align:'center',
			  isSelect:true,
			//   sorter: true,
			//   render: name => `${name.first} ${name.last}`,
			  width: 80,
			  fixed:'left',
			  render: () => <Button type="link">编辑</Button>,
			},
			{
				title: '名称',
				key: 'title',
				ellipsis: true,
				isSelect:true,
				// fixed:'left',
				dataIndex: 'title',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
		
			  {
				title: '展示金额',
				key: 'showprice',
				ellipsis: true,
				isSelect:true,
				dataIndex: 'showprice',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  {
				title: '实际充值金额',
				key: 'realprice',
				ellipsis: true,
				isSelect:true,
				dataIndex: 'realprice',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 150,
			  },
			  
			  {
				title: '是否展示',
				key: 'isshow',
				ellipsis: true,
				isSelect:true,
				dataIndex: 'isshow',
				// filters: [{ text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }],
				width: 100,
				render(isshow){
					switch (isshow){
						case "1": return ("是");
						case "2": return ("否");
						default:return "未知状态"
					}
				}
			  },
			  {
				title: '创建时间',
				key: 'usercreateAt',
				ellipsis: true,
				isSelect:true,
				dataIndex: "meta.createdAt",
				sorter: true,
				render:Utils.formateDate,
		 
			  },
			  {
				title: '更新时间',
				key: 'userupdateAt',
				ellipsis: true,
				isSelect:true,
				render:Utils.formateDate,
				sorter: true,
				dataIndex: "meta.updatedAt",
		
			  },
		  ],
	minasimfieldSelect:[],//微信小程序动态显示字段,已选择
	minapricefieldSelect:[],//微信小程序动态显示字段,已选择
}

const initReg={
	iccidNum:'',
}
// reducer
export function user(state=initState, action){
	switch(action.type){
		case AUTH_SUCCESS:
			return {...state,redirectTo:'/admin/home',...action.payload}
		case ICCID_SUCCESS:
			return {...state,regTo:'/regphone',...action.payload}
		case LOAD_DATA:
			return {...state,...action.payload}
		case ERROR_MSG:
			return {...state, isAuth:false, msg:action.msg}
		case LOGOUT:
			return {...initState,redirectTo:'/login'}
		case REDIRECTTO:
			return{...state,...action.payload}
		case ICCIDDATA:
			return {...state,...action.payload}
		default:
			return state
	}
}

function authSuccess(obj){
	const {...data} = obj
	console.log("data---->>>>>",data)
	return {type: AUTH_SUCCESS, payload:data}
}

function loadSuccess(obj){
	const {...data} = obj
	return {type: LOAD_DATA, payload:data}
}

function iccidSuccess(obj){
	const {...data} = obj
	console.log("iccid---->>>>>",data)
	return {type: ICCID_SUCCESS, payload:data}
}

function errorMsg(msg){
	return { msg, type:ERROR_MSG }
}

export function loadData(userinfo){
	return { type:LOAD_DATA, payload:userinfo}
}

// export function loadData(){
// 	return dispatch=>{
// 		request(()=>{})({
// 			method: 'get',
// 			url: '/admin/info'
// 		})
// 		.then(res => {
// 			console.log("loadRes---->>>>>",res)
// 					dispatch(loadSuccess(res))
// 		})
// 	}
// }
function logout(){
	return { type:LOGOUT }
}
export function logoutSubmit(){
	return dispatch=>{
		axios.get('/admin/logout')
		.then(res => {
			console.log("logoutres---->>>>>",res)
					dispatch(logout())
		})
	}

}

export function login(values,fn){
	return dispatch=>{
		request(fn)({
			method: 'post',
			url: '/admin/login',
			data: {
				...values
			}
		})
		.then(res => {
			console.log("res---->>>>>",res)
					dispatch(authSuccess(res))
		})
	}
}

export function register(values,fn){
	return dispatch=>{
		request(fn)({
			method: 'post',
			url: '/admin/register',
			data: {
				...values
			}
		})
		.then(res => {
			console.log("res---->>>>>",res)
					dispatch(iccidSuccess(res))
		})
	}
}

export function routeData(routeDir){
	const {...data} = routeDir
	console.log("data---->>>>>",data)
	return { type:REDIRECTTO ,payload:routeDir}
}

export function iccidData(iccid){
	const {...data} = iccid
	console.log("data---->>>>>",data)
	return { type:ICCIDDATA ,payload:data}
}

export function updateMinasim(minasimdata){
	// const {...data} = routeDir
	console.log("update--minasimdata-->>>>>",minasimdata)
	return { type:LOAD_DATA ,payload:minasimdata}
}
